import React from 'react';
import "./styles.css";
import logo from '../../access/image/logo.svg'

const Thanks = () => {
  const website = "http://www.cardiotrack.mx";
    return (
    <div>
        <a href={website} target="blank">
          <img className='logo' src={logo} />
        </a> 
        <div className='container'>
          Gracias por su mensaje, le responderemos en la brevedad.
        </div>
      <footer>Cardiotrack ®
2024 - <a href={website} target="blank">Sitio Web</a> </footer>
    </div>
    )
};

export default Thanks;