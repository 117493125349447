import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import "./styles.css";
import logo from '../../access/image/logo.svg'

const Contact = () => {
  const [formError, setFormError] = useState({ message: '' });
  const [request, setRequest] = useState(false);
  const website = "http://www.cardiotrack.mx";
        const form = useRef();
  const handleFormSendEmail = (e) => {
          e.preventDefault();
          setRequest(true);
          emailjs
            .sendForm('service_7dk2cms', 'template_ffj23jq', form.current, {
              publicKey: 'sW1h-nY7j9WA9GPwg',
            })
            .then(
              () => {
                setRequest(false);
                window.location.replace('https://www.cardiotrack.mx/gracias');
              },
              (error) => {
                setRequest(false);
                setFormError({message:error.text});
              },
            );
  };
    return (
      <div>
      <a href={website} target="blank">
        <img className='logo' src={logo} />
      </a> 
      <div className='container'>
        <form ref={form} onSubmit={handleFormSendEmail}>
            <input type="text" name="user_name" placeholder='Nombre' required/>
            <input type="text" name="user_company" placeholder='Empresa' required/>
            <input type="email" name="user_email" placeholder='Email Corporativo' required/>
          <input type="tel" name="user_telephone" placeholder='Celular / Teléfono de contacto' required />
          <p>Equipo o producto de interés</p>
          <select name="user_equipe" placeholder='Equipo o producto de interés' required>
            <option value="CardioStation">
Cardio Station
            </option>
            <option value="CardioBreath">Cardio Breath</option>
            <option value="CardioAlert">Cardio Alert</option>
            <option value="CardioPosture">Cardio Posture</option>
          </select>
            <textarea name="message" placeholder='Mensaje o Descripción del proyecto' required />
            {!request ? <input type="submit" value="Enviar"/> : `Envíando...`}
          </form>
          {formError?.message ? <div className='message-error'>{formError.message}</div> : null}
      </div>
      <footer>Cardiotrack ®
2024 - <a href={website} target="blank">Sitio Web</a> </footer>
    </div>
    )
};

export default Contact;