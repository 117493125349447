import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactPage from './pages/contact-page';
import ThanksPage from './pages/thanks-page';

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ContactPage />}/>
        <Route path="/thanks" element={<ThanksPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

reportWebVitals();
